<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <img src="/assets/sabre-img/paymenticon.png" />
          </span>
          <strong>{{$t("sabre.booking.payment")}}</strong>
        </div> לורם איפסום דולור קומיט
      </h3>
    </div>
    <div class="flightbooking_form_middel">
      <div class="payment_area">
        <h4>סך הכל תשלום : <strong>₪310&nbsp;7.08</strong></h4>

        <iframe id="payment-verified" v-if="path" title="Card verifying" :src="path"></iframe>
        <content-loading v-else type='poll'/>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'payment-form',
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  computed: {
    ...mapGetters({
      path: 'GET_SABRE_PAYMENT_URL',
    }),
  },
};

</script>

<style scoped>
  #payment-verified{
    width: 100%;
    height: 540px;
    border: none;
  }
</style>
